var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h1',{staticClass:"page-header"},[_vm._v(" "+_vm._s(_vm.$t('teachingCalendar.page_title'))+" ")]),_c('ts-breadcrumb',{attrs:{"items":[
					{ text: _vm.$t('toHomePage'), href: '/' },
					{
						text: _vm.$t(
							'teachingCalendar.page_title'
						),
						active: true
					}
				]}})],1),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"row tw-mb-6"},[_c('div',{staticClass:"col-md-2 form-group tw-px-2"},[_c('label',{staticClass:"form-col-label control-label tw-mb-2 required"},[_vm._v(_vm._s(_vm.$t('teachingCalendar.dateRange')))]),_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","placement":"bottom-end","placeholder":_vm.$t('teachingCalendar.selectDateRange'),"transfer":true,"format":"dd-MM-yyyy"},on:{"on-change":_vm.dateRangeChange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)])]),_c('div',{staticClass:"overflow-auto"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var record = ref.record;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(record.schedule_date)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s((record.time_from && record.time_to) ? _vm.tConvert(record.time_from) + ' - ' + _vm.tConvert(record.time_to) : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(record.staff_name ? record.staff_name.employee_name_en : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(record.staff_substitute_name ? record.staff_substitute_name.employee_name_en : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(record.remark)+" ")])]}}])})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }